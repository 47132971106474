import React, { useState }from "react";
import Layout from "../components/layout-2";
import SEO from "../components/seo";
import MenuDrawer from "../components/atoms/menuDrawer";

function ContactPage() {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <Layout>
      <SEO
        keywords={[
          `épület`,
          `enteriőr`,
          `ingatlan`,
          `iroda`,
          `lakás`,
          `építészeti`,
          `fotó`,
          `fotós`,
          `fotográfus`,
        ]}
        title="Kapcsolat | GÁBOR TAMÁS"
      />
      <section className="flex justify-center px-4 mt-12 md:mt-16">
        <div className="flex flex-col">
          <div className="w-full max-w-xl">
            <h2 className="mb-6 text-xl font-bold text-black md:text-2xl 3xl:text-2xl">
              Küldj üzenetet az alábbi ürlap segítségével
            </h2>
            <p className="mb-12 leading-loose md:mb-16">
              Kérlek, töltsd ki az alábbi űrlapot a felmerült kérdéseiddel
              kapcsolatban.
              <br />
              Árajánlat kéréshez adj meg minél több releváns információt, mint
              például a projekt témáját, helyét, technikai elvárásaidat, a
              szükséges képek mennyiségét, a képek tervezett felhasználását,
              célját, koncepció anyagot, weboldalt stb. 
            </p>
          </div>
          <div className="w-full h-px mb-12 bg-gray-300 md:mb-16"></div>
          <div className="w-full max-w-xl mb-10 md:mb-16 ">
            <form action="https://submit-form.com/BhHb8Hs4" method="POST">
              <div className="flex flex-row">
                <div className="w-full mb-6 mr-10">
                  <input
                    type="hidden"
                    name="_email.subject"
                    value="Weboldal megkeresés"
                  />
                  <input
                    type="hidden"
                    name="_redirect"
                    value="https://tamasgabor.com/uzenet-visszajelzes/"
                  />

                  <label
                    className="block mb-2 text-base font-bold tracking-wide text-gray-700"
                    htmlFor="lastname"
                  >
                    Vezetéknév
                  </label>

                  <input
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-100 border border-gray-700 rounded outline-none appearance-none hover:border-indigo-500 focus:border-indigo-500 focus:bg-white"
                    id="lastname"
                    name="last-name"
                    type="text"
                    required
                  />
                </div>
                <div className="w-full">
                  <label
                    className="block mb-2 text-base font-bold tracking-wide text-gray-700"
                    htmlFor="firstname"
                  >
                    Keresztnév
                  </label>

                  <input
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-100 border border-gray-700 rounded outline-none appearance-none hover:border-indigo-500 focus:border-indigo-500 focus:bg-white"
                    id="firstname"
                    name="first-name"
                    type="text"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-full mb-6">
                  <label
                    className="block mb-2 text-base font-bold tracking-wide text-gray-700"
                    htmlFor="email"
                  >
                    E-mail cím
                  </label>

                  <input
                    className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-100 border border-gray-700 rounded outline-none appearance-none hover:border-indigo-500 focus:border-indigo-500 focus:bg-white"
                    id="email"
                    name="email"
                    type="email"
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  className="block mb-2 text-base font-bold tracking-wide text-gray-700"
                  htmlFor="subject"
                >
                  Megkeresés tárgya
                </label>

                <input
                  className="block w-full px-4 py-3 mb-6 leading-tight text-gray-700 bg-gray-100 border border-gray-700 rounded outline-none appearance-none hover:border-indigo-500 focus:border-indigo-500 focus:bg-white"
                  id="subject"
                  name="subject"
                  type="text"
                  required
                />

                <label
                  className="block mb-2 text-base font-bold tracking-wide text-gray-700 "
                  htmlFor="message"
                >
                  Üzenet
                </label>

                <textarea
                  className="block w-full px-4 py-3 mb-3 leading-tight text-gray-700 bg-gray-100 border border-gray-700 rounded outline-none appearance-none hover:border-indigo-500 focus:border-indigo-500 focus:bg-white"
                  id="message"
                  name="message"
                  type="text"
                  rows="6"
                  required
                />

                <input
                  type="checkbox"
                  name="_honeypot"
                  style={{ display: `none` }}
                  tabIndex="-1"
                  autoComplete="off"
                />

                <div className="flex flex-col justify-between pt-6 md:flex-row">
                  <div className="flex items-center my-3">
                    <div
                      animate
                      className={`flex bg-gray-400 rounded-2xl cursor-pointer p-1 w-12 mr-6 ${
                        isToggled ? "justify-end bg-indigo-500" : ""
                      }`}
                      onClick={() => setIsToggled(!isToggled)}
                    >
                      <div animate className="w-5 h-5 bg-white rounded-full" />
                    </div>
                    <label className="font-bold tracking-wide text-gray-700 inline-blocktext-base">
                      Igazolom, hogy nem vagyok spam robot.
                    </label>
                  </div>
                  {isToggled ? (
                    <div className="mt-10 md:mt-0">
                      <button
                        type="submit"
                        className="w-full px-10 py-4 text-base font-semibold leading-none text-center text-white transition duration-200 ease-in-out bg-indigo-500 rounded-md md:text-right mb:w-auto disabled:opacity-20 focus:outline-none hover:bg-indigo-600"
                      >
                        Küldés
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <MenuDrawer/>
    </Layout>
  );
}

export default ContactPage;
